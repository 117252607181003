export default [
  {
    path: '/parceiro',
    name: 'dashboard-affiliate',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/index.vue')
  },
  {
    path: '/parceiro/produtos',
    name: 'bff-partners-products',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/products/index.vue')
  },
  {
    path: '/parceiro/produto/:id',
    name: 'bff-partners-product-details',
    meta: { layout: 'partner' },
    component: () => import('../pages/partners/products/edit.vue')
  },
  // {
  //   path: '/parceiro/clientes',
  //   name: 'bff-partners-customers-list',
  //   meta: { layout: 'partner' },
  //   component: () => import('../pages/partners/customers/index.vue')
  // },
  // {
  //   path: '/parceiro/cliente/:id',
  //   name: 'bff-partners-customers-details',
  //   meta: { layout: 'partner' },
  //   component: () => import('../pages/partners/customers/edit.vue')
  // },
  // {
  //   path: '/parceiro/proposta/:id?',
  //   name: 'bff-proposals',
  //   meta: { layout: 'empty' },
  //   component: () => import('../pages/proposals/index.vue')
  // }

  // ROTAS DOS CLIENTES (MEMBERS)
  {
    path: '/meu-planejamento/:id',
    name: 'bff-proposal-details',
    meta: { layout: 'member' },
    component: () => import('../pages/members/dasboard/proposal/details.vue')
  },
  {
    path: '/seus-planejamentos',
    name: 'dashboard-bff',
    meta: { layout: 'member' },
    component: () => import('../pages/members/dasboard/index.vue')
  }
]
